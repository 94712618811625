import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  displayPrice,
} from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
  STOCK_MULTIPLE_ITEMS,
  STOCK_INFINITE_MULTIPLE_ITEMS,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { getListingPrice, userDisplayNameAsString } from '../../util/data';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import {
  ModalInMobile,
  FieldTextInput,
  PrimaryButton,
  AvatarSmall,
  H1,
  H2,
  H4,
} from '../../components';

import css from './OrderPanel.module.css';

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// Adjust this breakpoint for mobile vs. desktop logic
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    let formattedPrice = formatMoney(intl, price);
    if (
      formattedPrice === '$0.00' ||
      formattedPrice === '€0.00' ||
      formattedPrice === 'CA$0.00' ||
      formattedPrice === '£0.00'
    ) {
      formattedPrice = 'Click for Price';
    }
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location
) => {
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
  } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  const dayPricings = publicData?.dayPricings;
  const listingPrice = getListingPrice(price, dayPricings);
  const { formattedPrice, priceTitle } = priceData(listingPrice, marketplaceCurrency, intl);

  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {formattedPrice !== 'Click for Price' ? (
        <div className={css.perUnitInCTA}>
          <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
        </div>
      ) : null}
    </div>
  ) : (
    <div className={css.priceContainer}>
      <p className={css.price}>
        {dayPricings ? (
          <FormattedMessage id="ListingPage.dayBasedPrice" values={{ formattedPrice }} />
        ) : (
          formattedPrice
        )}
      </p>
      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
    addonsFromPublicData,
    discountsFromPublicData,
    dayPricingsFromPublicData,
    datePricingsFromPublicData,
    config,
  } = props;

  const publicData = listing?.attributes?.publicData || {};
  const { listingType, unitType, transactionProcessAlias = '' } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const price = listing?.attributes?.price;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;
  const showPriceMissing = isPaymentProcess && !price;
  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates = isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;
  const showProductOrderForm = isPurchase && typeof currentStock === 'number';

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const allowOrdersOfMultipleItems = [STOCK_MULTIPLE_ITEMS, STOCK_INFINITE_MULTIPLE_ITEMS].includes(
    listingTypeConfig?.stockType
  );

  const { pickupEnabled, shippingEnabled } = publicData;
  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;
  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');
  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  // FareHarbor logic
  const fareHarbor = publicData?.fareHarbor;
  const [isIframeLoading, setIframeLoading] = useState(!!fareHarbor);
  const handleIframeLoad = () => {
    setIframeLoading(false);
  };
  useEffect(() => {
    if (!fareHarbor) {
      setIframeLoading(false);
    }
  }, [fareHarbor]);

  // -------------------------------------
  // 1) Detect if user is on mobile layout
  // -------------------------------------
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 1200
  );

  useEffect(() => {
    const onResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const isMobileLayout = windowWidth <= MODAL_BREAKPOINT;

  // --------------------------------------
  // 2) Reporting state & handle logic
  // --------------------------------------
  const [hasReported, setHasReported] = useState(false);
  const listingUrl = typeof window !== 'undefined' ? window.location.href : 'unknown';
  const listingId = listing?.id?.uuid || 'unknown';

  const handleReportClick = () => {
    setHasReported(true);
    fetch('https://api.rentaltide.com/api/communication/slack', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        message: `Issue from ${listingUrl} for listing ${listingId}: No pricing or other issue reported.`,
        channel: 'reported-listings',
      }),
    });
  };

  // --------------------------------------
  // 3) Render the "report" link or success
  // --------------------------------------
  const reportSection = (
    <div className={css.reportIssueContainer}>
      {hasReported ? (
        <p className={css.thankYouText}>
          Thank you for reporting this listing and helping the community!
        </p>
      ) : (
        <button type="button" className={css.reportIssueLink} onClick={handleReportClick}>
          Issue with pricing or content? Report listing here
        </button>
      )}
    </div>
  );

  // Utility small components
  const PriceMissing = () => (
    <p className={css.error}>
      <FormattedMessage id="OrderPanel.listingPriceMissing" />
    </p>
  );
  const InvalidCurrency = () => (
    <p className={css.error}>
      <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
    </p>
  );

  // --------------------------------------
  // 4) Return
  // --------------------------------------
  return (
    <div className={classes}>
      {/* If listing has fareHarbor URL, show it in modal on mobile */}
      {fareHarbor ? (
        <ModalInMobile
          containerClassName={css.modalContainer}
          id="OrderFormInModal"
          isModalOpenOnMobile={isOrderOpen}
          onClose={() => closeOrderModal(history, location)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          {!isMobileLayout && reportSection}

          <div className={css.orderHeading}>
            {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
            {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
          </div>

          {isIframeLoading && (
            <div className={css.loadingIcon}>
              <div className={css.spinner}></div>
              <div className={css.loadingText}>Loading your adventure...</div>
            </div>
          )}

          <iframe
            src={fareHarbor}
            title="WetRentals Order Page"
            style={{ marginTop: '10px', width: '100%', height: '90vh', border: 'none' }}
            onLoad={handleIframeLoad}
          />
          {isMobileLayout ? reportSection : null}
        </ModalInMobile>
      ) : (
        <ModalInMobile
          containerClassName={css.modalContainer}
          id="OrderFormInModal"
          isModalOpenOnMobile={isOrderOpen}
          onClose={() => closeOrderModal(history, location)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          {/* If desktop, show the report link near the top */}
          {!isMobileLayout && reportSection}

          <div className={css.modalHeading}>
            <H1 className={css.heading}>{title}</H1>
          </div>

          <div className={css.orderHeading}>
            {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
            {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
          </div>

          <PriceMaybe
            price={price}
            publicData={publicData}
            validListingTypes={validListingTypes}
            intl={intl}
            marketplaceCurrency={marketplaceCurrency}
          />

          <div className={css.author}>
            <AvatarSmall user={author} className={css.providerAvatar} />
            <span className={css.providerNameLinked}>
              <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
            </span>
            <span className={css.providerNamePlain}>
              <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
            </span>
          </div>

          {showPriceMissing ? (
            <PriceMissing />
          ) : showInvalidCurrency ? (
            <InvalidCurrency />
          ) : showBookingTimeForm ? (
            <BookingTimeForm
              className={css.bookingForm}
              formId="OrderPanelBookingTimeForm"
              lineItemUnitType={lineItemUnitType}
              onSubmit={onSubmit}
              price={price}
              marketplaceCurrency={marketplaceCurrency}
              dayCountAvailableForBooking={dayCountAvailableForBooking}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              timeZone={timeZone}
              marketplaceName={marketplaceName}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              payoutDetailsWarning={payoutDetailsWarning}
            />
          ) : showBookingDatesForm ? (
            <BookingDatesForm
              className={css.bookingForm}
              formId="OrderPanelBookingDatesForm"
              lineItemUnitType={lineItemUnitType}
              onSubmit={onSubmit}
              price={price}
              marketplaceCurrency={marketplaceCurrency}
              dayCountAvailableForBooking={dayCountAvailableForBooking}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              timeZone={timeZone}
              marketplaceName={marketplaceName}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              payoutDetailsWarning={payoutDetailsWarning}
              codes={publicData?.codes}
              rentalLocation={publicData?.rentalLocation}
              publicData={publicData}
              addonsFromPublicData={addonsFromPublicData}
              discountsFromPublicData={discountsFromPublicData}
              dayPricingsFromPublicData={dayPricingsFromPublicData}
              datePricingsFromPublicData={datePricingsFromPublicData}
              charterBoat={publicData?.charterBoat}
              charterBoatSeats={publicData?.charterBoatSeats}
              config={config}
            />
          ) : showProductOrderForm ? (
            <ProductOrderForm
              formId="OrderPanelProductOrderForm"
              onSubmit={onSubmit}
              price={price}
              marketplaceCurrency={marketplaceCurrency}
              currentStock={currentStock}
              allowOrdersOfMultipleItems={allowOrdersOfMultipleItems}
              pickupEnabled={pickupEnabled && displayPickup}
              shippingEnabled={shippingEnabled && displayShipping}
              displayDeliveryMethod={displayPickup || displayShipping}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              marketplaceName={marketplaceName}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              onContactUser={onContactUser}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              payoutDetailsWarning={payoutDetailsWarning}
            />
          ) : showInquiryForm ? (
            <InquiryWithoutPaymentForm formId="OrderPanelInquiryForm" onSubmit={onSubmit} />
          ) : !isKnownProcess ? (
            <p className={css.errorSidebar}>
              <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
            </p>
          ) : null}

          {/* If mobile, place the report link at bottom of modal. */}
          {isMobileLayout && reportSection}
        </ModalInMobile>
      )}

      {/* "Open order form" button (visible on wider screens, outside ModalInMobile) */}
      <div className={css.openOrderForm}>
        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          showCurrencyMismatch
        />

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <PrimaryButton
            onClick={handleSubmit(isOwnListing, isClosed, showInquiryForm, onSubmit, history, location)}
            disabled={isOutOfStock}
          >
            {isBooking ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
            ) : isOutOfStock ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
            ) : isPurchase ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
            ) : (
              <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
            )}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  payoutDetailsWarning: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  payoutDetailsWarning: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
